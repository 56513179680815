<template>
  <div class="app-container">
    <el-button type="primary" :loading="buttonloading" class="mt-3 mb-3 float-right" v-b-modal.addRow v-if="permissionList.includes(permission.add)" icon="el-icon-plus">{{$t('button.add')}}</el-button>
    <el-table :data="tableData" v-loading="loading" style="width: 100%;" @expand-change="expandChange" @row-click="expandRow" class="mt-3" ref="tableTest" border fit highlight-current-row>
		<el-table-column v-for="title in ajaxTitles" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
			<template slot="header">
				<p class="search-label">{{title.label}}</p>
				<b-form-input :placeholder="title.label" @keyup.enter.native="initial" v-model="searchData[title.prop]" @change="saveData()"></b-form-input>
			</template>
		</el-table-column>
		<el-table-column type="expand">
			<template slot-scope="scope">
				<el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-edit">{{$t('button.edit')}}</el-button>
				<el-button type="success" :loading="buttonloading" @click="getPermissionRow(scope.row.id)" v-if="permissionList.includes(permission.permission)" icon="el-icon-star-off">{{$t('button.permission')}}</el-button>
				<el-button type="danger" :loading="buttonloading" @click="deleteRow(scope.row.id)" v-if="permissionList.includes(permission.delete)" icon="el-icon-delete">{{$t('button.delete')}}</el-button>
			</template>
		</el-table-column>
    </el-table>

    <pagination v-show="total > 0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"  @pagination="pagination"/>

    <el-tooltip placement="top" :content="$t('general.back_to_top')">
		<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
    </el-tooltip>

    <b-modal id="addRow" :title="$t('menu.tools_job_add')" @hide="clearDataList()" hide-footer>
		<el-form @keyup.enter.native="addRow()">
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_category')}}</template>
				<el-select v-model="dataList.category" :placeholder="$t('msg.msg_select')" class="w-100">
					<el-option :label="$t('general.admin')" value="admin"></el-option>
					<el-option :label="$t('general.member')" value="member"></el-option>
				</el-select>
			</b-form-group>
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_code')}}</template>
				<b-form-input v-model="dataList.code"></b-form-input>
			</b-form-group>
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_sort')}}</template>
				<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
			</b-form-group>
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_status')}}</template>
				<el-switch v-model="dataList.status" active-value="1" inactive-value="0"></el-switch>
			</b-form-group>
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_security')}}</template>
				<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
			</b-form-group>
			<el-tabs class="mb-3">
				<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
					<b-form-group label-cols="12" label-cols-lg="4">
						<template slot="label">{{$t('admin_general.table_name')}}</template>
						<b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
					</b-form-group>
				</el-tab-pane>
			</el-tabs>
		</el-form>
		
		<el-footer align="center" class="element-footer">
			<el-button size="medium" @click="$bvModal.hide('addRow')">{{$t('button.cancel')}}</el-button>
			<el-button size="medium" type="primary" @click="addRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
		</el-footer>
    </b-modal>

    <b-modal id="editRow" :title="$t('menu.tools_job_edit')" @hide="clearDataList()" hide-footer>
		<el-form @keyup.enter.native="editRow()">
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_category')}}</template>
				<el-select v-model="dataList.category" :placeholder="$t('msg.msg_select')" class="w-100">
					<el-option label="Admin" value="admin"></el-option>
					<el-option label="Member" value="member"></el-option>
				</el-select>
			</b-form-group>
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_code')}}</template>
				<b-form-input v-model="dataList.code"></b-form-input>
			</b-form-group>
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_sort')}}</template>
				<el-input-number v-model="dataList.sort" :min="0" class="w-100" :precision="0"></el-input-number>
			</b-form-group>
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_status')}}</template>
				<el-switch v-model="dataList.status" active-value="1" inactive-value="0"></el-switch>
			</b-form-group>
			<b-form-group label-cols="12" label-cols-lg="4">
				<template slot="label">{{$t('admin_general.table_security')}}</template>
				<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
			</b-form-group>
			<el-tabs class="mb-3">
				<el-tab-pane v-for="item in languageList" :key="item.id" :item="item" :label="item.name">
					<b-form-group label-cols="12" label-cols-lg="4">
						<template slot="label">{{$t('admin_general.table_name')}}</template>
						<b-form-input v-model="dataList.name[item.id]" :disabled="loading"></b-form-input>
					</b-form-group>
				</el-tab-pane>
			</el-tabs>
		</el-form>

		<el-footer align="center" class="element-footer">
			<el-button size="medium" @click="$bvModal.hide('editRow')">{{$t('button.cancel')}}</el-button>
			<el-button size="medium" type="primary" @click="editRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
		</el-footer>
    </b-modal>

    <b-modal id="permissionRow" :title="$t('menu.tools_job_permission')" hide-footer size="xl">
		<el-form label-width="80px" class="mt-3" @keyup.enter.native="permissionRow">
			<div class="row">
				<el-container>
					<el-header style="height:30px;">
						<el-checkbox v-model="checkAll" @change="handleCheckAllChange">{{$t('button.check_all')}}</el-checkbox>
					</el-header>
					<el-main>
						<div class="col-12 mb-3" v-for="(item, index) in jobPermissionList" :item="item" :key="index">
							<el-checkbox class="job_checkbox" :label="item.category" @change="handleCheckAllChangePart($event, item.category)">{{$t('menu.'+item.category)}}</el-checkbox>
							<div style="margin: 15px 0;"></div>
							<div class="row">
								<div v-for="permission in item.sub_list" :key="permission.id" class="col-4 col-sm-4 col-md-3">
									<el-checkbox-group v-model="jobPermissionDetail">
										<el-checkbox :label="permission.id" :checked="jobPermissionDetail.includes(permission.id)">{{$t('menu.'+permission.file)}}</el-checkbox>
									</el-checkbox-group>
								</div>
							</div>
						</div>
					</el-main>
					
					<el-footer align="center">
						<el-button size="medium" @click="$bvModal.hide('permissionRow')">{{$t('button.cancel')}}</el-button>
						<el-button size="medium" type="primary" @click="permissionRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
					</el-footer>
				</el-container>
			</div>
		</el-form>
    </b-modal>
  </div>
</template>

<script>
import { getLocalStorage } from "@/system/store/localstorage";
import { postMethod } from "@/system/model/post/post";
import { getDAES, getEAES } from "@/system/library/security";
import pagination from "@/components/pagination";
import backtotop from "@/components/backtotop";

let searchForm = {
	pagination: 1,
	limit: 10,
	id: "",
	category: "",
	code: "",
	sort: "",
	status: ""
}

export default {
	components: { pagination, backtotop },
	inject: ["preloader"],
	data() {
		return {
		loading: true,
		buttonloading: false,
		tableData: [],
		total: 0,
		errors: [],
		submitForm: {
			id: "",
			security: ""
		},
		postData: {
			data: "",
			language: ""
		},
		searchData:Object.assign({}, searchForm),
		listQuery: {
			page: 1,
			limit: 10
		},
		ajaxTitles: [ {
			prop: "id",
			label: this.$t("admin_general.table_id"),
			width: "50"
		},
		{
			prop: "category",
			label: this.$t("admin_general.table_category"),
			width: "100"
		},
		{
			prop: "code",
			label: this.$t("admin_general.table_code"),
			width: "100"
		},
		{
			prop: "sort",
			label: this.$t("admin_general.table_sort"),
			width: "60"
		},
		{
			prop: "status",
			label: this.$t("admin_general.table_status"),
			width: "60"
		}],
		coinList: [],
		dataList: {
			category: "",
			code: "",
			sort: "10",
			status: "",
			security: "",
			name: []
		},
		permission: {
			ajaxTable: "7HHKMG",
			add: "UPMHK7",
			DBadd: "MKRMOU",
			edit: "85YYPM",
			DBedit: "FMCC71",
			delete: "RCKB72",
			DBdelete: "IV2K1H",
			permission: 'N2QFI7',
			DBpermission: 'BCUKWK'
		},
		languageList: [],
		permissionList: [],
		jobPermissionDetail:[],
		jobPermissionList:[],
		postPermission:{
			id:'',
			permission:[]
		},
		isIndeterminateAll: false,
		isIndeterminate: false,
		checkAll: false,
		};
	},
	methods: {
		initial() {
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod("tools/job/ajaxTable", this.postData);
				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.tableData = data.datatable.data;
						self.total = parseInt(data.datatable.total_number);
						self.listQuery.page = parseInt(data.datatable.current_pagination);
						self.listQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
		},
		clearDataList() {
			this.dataList.category = "";
			this.dataList.code = "";
			this.dataList.sort = "10";
			this.dataList.security = "";
			this.dataList.status = 0;
		},
		addRow() {
			if(this.permissionList.includes(this.permission.DBadd)){
				this.preloader(true);
				this.buttonloading = true;
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = "";
				var self = this;
				var result = postMethod("tools/job/DBadd", this.postData);

				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.$message({
							message: data.returnMsg,
							type: "success"
						});
						
						self.$bvModal.hide("addRow");
						self.initial();
					} else {
						data.returnMsg.forEach(function(value) {
							text += value + "<br/>";
						});

						self.$method.popupErrorMessage(self, text);
					}
					self.buttonloading = false;
					self.preloader(false);
				});
			}
		},
		getEditRow(id) {
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod("tools/job/edit", this.postData);
				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.dataList = data.thisDetail;
						self.$bvModal.show("editRow");
					}
					self.buttonloading = false;
				});
			}
		},
		editRow() {
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = "";
				var self = this;
				var result = postMethod("tools/job/DBedit", this.postData);

				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.$message({
							message: data.returnMsg,
							type: "success"
						});
						
						self.$bvModal.hide("editRow");
						self.initial();
					} else {
						data.returnMsg.forEach(function(value) {
							text += value + "<br/>";
						});

						self.$method.popupErrorMessage(self, text);
					}

					self.buttonloading = false;
					self.preloader(false);
				});
			}
		},
		deleteRow(id) {
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = "";
				var self = this;
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = "";
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod("tools/job/DBdelete", this.postData);
					result.then(function(value) {
						var data = JSON.parse(getDAES(value.data));
						if (value.valid) {
							self.$message({
								type: "success",
								message: data.returnMsg
							});

							self.initial();
							self.buttonloading = false;
						} else {
							self.errors = data.returnMsg;

							self.errors.forEach(function(value) {
								text += value + "<br/>";
							});

							self.$method.popupErrorMessage(self, text);
							self.buttonloading = false;
						}
					});
				})
				.catch(() => {
					this.buttonloading = false;
				});
			}
		},
		getPermissionRow(id) {
			if(this.permissionList.includes(this.permission.permission)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = "";
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod("tools/job/permission", this.postData);
				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.jobPermissionDetail = data.thisDetail.permission;
						self.jobPermissionList = data.permissionList;
						self.$bvModal.show("permissionRow");
					}
					self.buttonloading = false;
					});
			}
		},
		permissionRow() {
			if(this.permissionList.includes(this.permission.DBpermission)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = "";
				this.postPermission.id = this.submitForm.id;
				this.postPermission.permission = this.jobPermissionDetail;
				this.postData.data = getEAES(JSON.stringify(this.postPermission));
				var text = "";
				var self = this;
				var result = postMethod("tools/job/DBpermission", this.postData);

				result.then(function(value) {
					var data = JSON.parse(getDAES(value.data));

					if (value.valid) {
						self.$message({
							message: data.returnMsg,
							type: "success"
						});
						
						self.$bvModal.hide("permissionRow");
						self.initial();
					} else {
						data.returnMsg.forEach(function(value) {
							text += value + "<br/>";
						});

						self.$method.popupErrorMessage(self, text);
					}

					self.buttonloading = false;
					self.preloader(false);
				});
			}
		},handleCheckAllChange(val) {
			this.jobPermissionDetail = [];
			if(val){
				var self = this;

				this.jobPermissionList.forEach(function(value){
					value.sub_list.forEach(function(value){
						self.jobPermissionDetail.push(value.id);
					});
				});
			}
		},handleCheckAllChangePart(val, id){
			var valList = [];
			var self = this;
			
			this.jobPermissionList.forEach(function(value){
				if(value.category == id){
					value.sub_list.forEach(function(value){
						valList.push(value.id);
					});
				}
			});

			if(val === true){
				valList.forEach(function(value){
					if(!self.jobPermissionDetail.includes(value)){
						self.jobPermissionDetail.push(value);
					}
				});
			}else{
				valList.forEach(function(value){
					var index = self.jobPermissionDetail.indexOf(value);
					if(index > -1){
						self.jobPermissionDetail.splice(index, 1);
					}
				});

			}
		},
		pagination() {
			this.searchData.pagination = this.listQuery.page;
			this.searchData.limit = this.listQuery.limit;
			this.initial();
		},
		expandRow(row) {
			this.$refs.tableTest.toggleRowExpansion(row);
		},
		expandChange(row, expandedRows) {
			if (expandedRows.length > 1) {
				this.$refs.tableTest.toggleRowExpansion(expandedRows[0]);
			}
		},saveData(){
			searchForm = this.searchData;
		}
	},
	created: function() {
		var currentLang = getLocalStorage("currentLang") ? getLocalStorage("currentLang") : "cn";
		this.postData.language = currentLang;
		this.languageList = JSON.parse(getLocalStorage('languageList'));
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.initial();
	}
};
</script>